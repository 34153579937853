<template>
  <header id="inicio">
    <video autoplay muted loop playsinline class="video-bg">
    <source src="../imagenes/banner.mp4" type="video/mp4" />
    Tu navegador no soporta videos HTML5.
  </video>
  <div class="overlay-text">
    Estacionamiento seguro y de confianza en el corazón de la ciudad.
  </div>
    <nav class="navbar" :class="{ 'scrolled': isScrolled }">
      <div class="logo">Cochera Belgrano</div>
      <!-- Botón de compartir en WhatsApp -->
      <a 
          :href="whatsappUrl" 
          target="_blank" 
          class="share-button" 
          aria-label="Compartir en WhatsApp"
        >
      <img src="../imagenes/share.png" alt="Compartir en WhatsApp" />
      </a>
      <button class="menu-toggle" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ul :class="{ open: isMenuOpen }">
        <li><a href="#inicio" @click="closeMenu">Inicio</a></li>
        <li><a href="#servicios" @click="closeMenu">Servicios</a></li>
        <li><a href="#ubicacion" @click="closeMenu">Ubicación</a></li>
        <li><a href="#contacto" @click="closeMenu">Contacto</a></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      isMenuOpen: false,  // Controla si el menú está abierto
      isScrolled: false,  // Controla si el navbar ha sido desplazado
      shareMessage: "Cochera Belgrano https://cocherabelgrano.com.ar",
    };
  },
  computed: {
    whatsappUrl() {
      return `whatsapp://send?text=${encodeURIComponent(this.shareMessage)}`;
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    // Manejador de evento para el scroll
    handleScroll() {
      // Si el scroll es mayor a 100px, cambia el estado de isScrolled a true
      this.isScrolled = window.scrollY > 100;
    },
  },
  mounted() {
    // Se agrega el evento de scroll al montar el componente
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    // Se elimina el evento de scroll cuando el componente se desmonta
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
/* Header */
header {
  color: white;
  padding: 130px 10px;
  text-align: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que el video cubra todo el espacio */
  z-index: 1; /* Envía el video al fondo */
  display: block;
}

.overlay-text {
  position: absolute;
  top: 40%; /* Ajusta la posición vertical */
  left: 40%; /* Ajusta la posición horizontal */
  color: white;
  font-size: 2rem; /* Tamaño del texto */
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Sombra para mejor visibilidad */
  z-index: 1; /* Asegura que el texto esté por encima del video */
  max-width: 650px; /* Limita el ancho del texto */
  line-height: 1.5; /* Mejora la legibilidad */
  text-align: justify; /* Justifica el texto para alinear los bordes */
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(5, 33, 5, 0); /* Inicialmente transparente */
  display: flex;
  align-items: center;
  z-index: 10;
  height: 70px;
  padding: 0 50px;
  box-sizing: border-box;
  transition: background-color 0.3s ease; /* Transición suave */
}

.navbar.scrolled {
  background-color: rgba(5, 33, 5); /* Fondo semi-transparente cuando se hace scroll */
}

.navbar .logo {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin-right: auto; /* El logo se queda a la izquierda */
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  transition: transform 0.3s ease;
  justify-content: flex-end; /* Los items se alinean a la derecha */
  margin-left: auto; /* Esto empuja el ul a la derecha */
}

.navbar ul li {
  display: inline;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5rem;
}

.navbar ul li a:hover {
  text-decoration: underline;
}


.share-button {
  display: none;
}




/* Botón hamburguesa */
.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 20;
  margin: 10px;
}

.menu-toggle span {
  display: block;
  width: 100%;
  height: 4px;
  background: white;
  border-radius: 2px;
}

/* Responsive */
@media (max-width: 768px) {

  .navbar {
    padding: 0 10px;
  }

  .overlay-text {
    font-size: 4vw; /* Aumenta ligeramente el tamaño del texto en pantallas medianas */
    top: 45%; /* Ajusta la posición vertical */
    left: 5%; /* Reduce el margen izquierdo */
    max-width: 80%; /* Reduce el ancho máximo */
  }

  .menu-toggle {
    display: flex;
  }


.share-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.share-button img {
  width: 100%;
  height: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.8));
}


  .navbar ul {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    gap: 15px;
    transform: translateX(-100%);
    overflow-y: auto;
    max-height: 70vh;
    background-color: rgba(5, 33, 5, 0.8);
    text-align: left;
    justify-content: center;    
  }
  

  .navbar ul.open {
    transform: translateX(0);
  }

}

@media (max-width: 480px) {
  .navbar ul {
    gap: 10px;
  }
  .overlay-text {
    font-size: 5vw; /* Aumenta el tamaño en pantallas muy pequeñas */
    top: 50%; /* Centra verticalmente en pantallas más pequeñas */
    left: 5%; /* Reduce aún más el margen izquierdo */
    max-width: 90%; /* Ajusta el ancho máximo */
    text-align: center; /* Centra el texto en pantallas pequeñas */
  }

}

@media (max-width: 890px) {
  .navbar ul {
    gap: 10px;
  }
  .overlay-text {
    font-size: 5vw; /* Aumenta el tamaño en pantallas muy pequeñas */
    top: 50%; /* Centra verticalmente en pantallas más pequeñas */
    left: 5%; /* Reduce aún más el margen izquierdo */
    max-width: 90%; /* Ajusta el ancho máximo */
    text-align: center; /* Centra el texto en pantallas pequeñas */
  }

}

</style>