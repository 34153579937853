<template>
    <div v-if="isMobile" class="whatsapp-widget" @click="openWhatsappApp">
      <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" alt="WhatsApp" />
    </div>
    <div v-else class="whatsapp-widget" @click="openWhatsappWeb">
      <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" alt="WhatsApp" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'WhatsappWidget',
    data() {
      return {
        isMobile: this.isMobileDevice()
      };
    },
    methods: {
      isMobileDevice() {
        // Detectar si el usuario está en un dispositivo móvil
        return /Mobi|Android/i.test(navigator.userAgent);
      },
      openWhatsappApp() {
        // URL para abrir la app de WhatsApp en dispositivos móviles
        const phoneNumber = '5492236056639'; // Cambia por el número de teléfono al que deseas contactar
        const url = `whatsapp://send?phone=${phoneNumber}`;
        window.location.href = url;
      },
      openWhatsappWeb() {
        // URL para abrir WhatsApp Web en PC
        const phoneNumber = '5492236056639'; // Cambia por el número de teléfono al que deseas contactar
        const url = `https://wa.me/${phoneNumber}`;
        window.open(url, '_blank');
      }
    }
  };
  </script>
  
  <style scoped>
  .whatsapp-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .whatsapp-widget img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  
  </style>